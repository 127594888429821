<template>
   <v-container fluid>
      <v-row>
         <v-col cols="12" md="6">
            <form @submit.prevent="validerPrix">
               <v-card max-width="800" class="ma-auto">
                  <v-toolbar color="secondary" dark flat>
                     <v-toolbar-title>Prix des consultations</v-toolbar-title>
                     <v-spacer />
                  </v-toolbar>
                  <v-card-text>
                     <v-text-field
                        label="Consultation bilan"
                        v-model="prix.bilan"
                        type="text"
                        outlined
                        rounded
                        required
                     />

                     <v-text-field
                        label="Consultation de suivi"
                        v-model="prix.suivi"
                        type="text"
                        required
                        outlined
                        rounded
                     />
                     <div class="text-center">
                        <v-btn
                           :loading="loading"
                           color="secondary"
                           type="submit"
                           large
                           depressed
                        >Valider</v-btn>
                     </div>
                  </v-card-text>
               </v-card>
            </form>
         </v-col>

         <v-col cols="12" md="6">
            <form @submit.prevent="validerNumero">
               <v-card max-width="800" class="ma-auto">
                  <v-toolbar color="accent" dark flat>
                     <v-toolbar-title>Numérotation des factures</v-toolbar-title>
                     <v-spacer />
                  </v-toolbar>
                  <v-card-text>
                     <v-text-field
                        label="Prochain numéro"
                        v-model="numero_facture.valeur"
                        type="number"
                        outlined
                        rounded
                        required
                     />
                     <div class="text-center">
                        <v-btn
                           :loading="loading"
                           color="accent"
                           type="submit"
                           large
                           depressed
                        >Valider</v-btn>
                     </div>
                  </v-card-text>
               </v-card>
            </form>
         </v-col>
      </v-row>
      <v-snackbar v-model="success" multi-line>
         Validé !
         <v-btn color="accent" text @click="success = false">Fermer</v-btn>
      </v-snackbar>
   </v-container>
</template>

<script>
   import { db } from "@/main";
   export default {
      data: () => ({
         loading: false,
         success: false,
         numero_facture: {},
         prix: {
            bilan: "",
            suivi: ""
         }
      }),
      mounted() {
         this.$emit("getTitre", "Paramètres");
         this.getParams();
      },
      methods: {
         getParams() {
            this.loading = true;
            let getPrix = db
               .collection("parametres")
               .doc("prix")
               .get();
            let getNumeroFacture = db
               .collection("parametres")
               .doc("numero_facture")
               .get();
            Promise.all([getPrix, getNumeroFacture]).then(res => {
               this.prix = res[0].data();
               this.numero_facture = res[1].data();
               this.loading = false;
            });
         },
         async validerPrix() {
            this.loading = true;
            await db
               .collection("parametres")
               .doc("prix")
               .update(this.prix);
            this.loading = false;
            this.success = true;
         },
         async validerNumero() {
            this.loading = true;
            await db
               .collection("parametres")
               .doc("numero_facture")
               .set({
                  valeur: Number(this.numero_facture.valeur)
               });
            this.loading = false;
            this.success = true;
         }
      }
   };
</script>

<style>
</style>
