<template>
   <v-overlay>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
   </v-overlay>
</template>

<script>
   import { auth } from "@/main";
   export default {
      created() {
         this.logout();
      },
      methods: {
         async logout() {
            this.$store.commit("logout");
            await auth.signOut();
            this.$router.push("login");
            // await this.$store.dispatch("logout");
            // this.$router.push({ name: "login" });
         }
      }
   };
</script>

<style>
</style>
