<template>
  <v-container fluid class="pt-5 text-center flex">
    <!-- <v-overlay :value="loading">
         <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>-->
    <v-card max-width="550" :loading="loading" class="ma-auto">
      <v-card-title>{{ nom_patient }}</v-card-title>
      <v-card-text>
        <v-text-field
          label="Date"
          type="date"
          outlined
          v-model="date"
          rounded
        ></v-text-field>
        <v-text-field
          label="Prix"
          type="number"
          outlined
          v-model="prix"
          rounded
        ></v-text-field>
        <Facture :prix="prix" :patient="patient" :date="date" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import Facture from "../components/Facture.vue";
import { db } from "../main";
import dayjs from "dayjs";

export default {
  name: "ImprimerFacture",
  components: { Facture },
  data: () => ({
    prix: null,
    date: dayjs().format("YYYY-MM-DD"),
    patient: {
      nom: "Chargement",
      prenom: "...",
    },
    loading: false,
  }),
  computed: {
    nom_patient() {
      return this.patient.nom + " " + this.patient.prenom;
    },
  },
  mounted() {
    this.getPatient();
    this.getPrix();
    this.$emit("getTitre", "Facturation");
  },
  methods: {
    async getPatient() {
      this.loading = true;

      let snapshot = await db
        .collection("patients")
        .doc(this.$route.params.id)
        .get();
      this.patient = snapshot.data();
      this.loading = false;
    },
    async getPrix() {
      let snapshot = await db.collection("parametres").doc("prix").get();
      if (this.$route.params.type === "bilan") {
        this.prix = snapshot.data().bilan;
      } else {
        this.prix = snapshot.data().suivi;
      }
    },
  },
};
</script>

<style>
</style>
