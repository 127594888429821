<template>
  <v-dialog persistent v-model="show" max-width="600">
    <v-form ref="form" @submit.prevent="valider">
      <v-card>
        <v-toolbar color="primary" dark elevation="0">
          <v-toolbar-title>{{
            editMode ? "Modifier le document" : "Nouveau document"
          }}</v-toolbar-title>
        </v-toolbar>

        <div class="pa-4">
          <v-alert type="error" v-if="messageErreur">{{
            messageErreur
          }}</v-alert>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="form.nom"
                label="Nom"
                rounded
                :rules="[rules.required]"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.url"
                label="URL"
                :rules="[rules.required]"
                rounded
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="9">
              <v-select
                v-model="form.categorieId"
                label="Catégorie"
                :items="categories"
                :rules="[rules.required]"
                rounded
                filled
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model.number="form.ordre"
                label="Ordre"
                type="number"
                step="1"
                :rules="[rules.required]"
                rounded
                filled
              ></v-text-field
            ></v-col>
          </v-row>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('close')">Annuler</v-btn>
          <v-btn dark type="submit" color="green" :loading="loading" depressed
            >Valider</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import rules from "../../lib/rules";
import { db } from "../../main";

export default {
  props: {
    document: Object,
  },

  data: () => ({
    editMode: false,
    show: true,
    messageErreur: "",
    loading: false,
    rules,
    form: {
      nom: "",
      url: "",
      ordre: 1,
      categorieId: "",
    },
  }),

  computed: {
    categories() {
      return this.$store.state.categories.map((cat) => ({
        text: cat.nom,
        value: cat.id,
      }));
    },
  },

  mounted() {
    if (this.document) {
      this.editMode = true;
      this.form = {
        nom: this.document.nom,
        url: this.document.url,
        ordre: this.document.ordre,
        categorieId: this.document.categorieId,
      };
    }
  },

  methods: {
    async valider() {
      this.messageErreur = "";
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;
        if (this.editMode) {
          console.log("edit");
          await db
            .collection("documents")
            .doc(this.document.id)
            .update(this.form);
        } else {
          await db.collection("documents").add(this.form);
        }
        this.$emit("close");
      } catch (error) {
        console.error(error);
        this.messageErreur = "Une erreur s'est produite.";
        this.loading = false;
      }
    },
  },
};
</script>

