<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <form @submit.prevent="login()">
              <v-card class="elevation-12">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title>Connexion</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <v-card-text>
                  <v-alert type="error" text prominent v-if="message != ''">{{
                    message
                  }}</v-alert>

                  <v-text-field
                    label="E-mail"
                    v-model="email"
                    prepend-icon="mdi-at"
                    type="email"
                  />

                  <v-text-field
                    label="Mot de passe"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    autofocus
                    required
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn :loading="loading" color="primary" type="submit"
                    >Se connecter</v-btn
                  >
                </v-card-actions>
              </v-card>
            </form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { auth, db } from "../../main";
export default {
  data() {
    return {
      loading: false,
      message: "",
      email: "maurine.devarieux@hotmail.fr",
      password: "",
      alert: false,
    };
  },
  methods: {
    async login() {
      this.message = "";
      this.loading = true;
      try {
        await auth.signInWithEmailAndPassword(this.email, this.password);

        // Tester si l'utilisateur n'est pas un patient !
        const testUser = await db
          .collection("patients")
          .where("userId", "==", auth.currentUser.uid)
          .get();
        if (!testUser.empty) {
          await auth.signOut();
          this.message = "Accès interdit.";
          this.loading = false;
        } else {
          this.$router.push("patients");
        }
      } catch (error) {
        this.loading = false;
        this.message = "Une erreur s'est produite.";
        if (error.message) this.message = error.message;
        if (error.code == "auth/wrong-password")
          this.message = "Mauvais mot de passe.";
        if (error.code == "auth/user-not-found")
          this.message = "Cette adresse email n'existe pas.";
      }
    },
  },
};
</script>
