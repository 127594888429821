<template>
  <v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-col>
      <v-container fluid>
        <!-- Alert patient supprimé avec succes  -->

        <v-alert
          dismissible
          v-model="alert"
          text
          dense
          color="teal"
          icon="mdi-check"
          border="left"
          >Patient supprimé avec succès.</v-alert
        >

        <!-- / fin alert  -->

        <v-row>
          <v-col cols="12" md="9">
            <!-- champs de recherche  -->
            <v-text-field
              v-model="search"
              label="Filtre de recherche"
              placeholder="Rechercher..."
              autocomplete="false"
              outlined
              rounded
            ></v-text-field>
            <!-- / fin du champs de recherche  -->
          </v-col>
          <v-col cols="12" md="3">
            <v-switch
              class="ml-2"
              v-model="archives"
              label="Archivés"
            ></v-switch>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="patientsFiltres"
          :items-per-page="15"
          class="elevation-1 mb-16"
          @click:row="goToPatient"
          :search="search"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.action="{ item }">
            <v-btn icon @click.stop="archiver(item.id)" v-if="!item.isArchive">
              <v-icon color="blue">mdi-archive-arrow-down</v-icon></v-btn
            >
            <v-btn
              icon
              @click.stop="desarchiver(item.id)"
              v-if="item.isArchive"
            >
              <v-icon color="green">mdi-archive-arrow-up</v-icon></v-btn
            >
            <v-btn icon @click.stop="editPatient(item.id)"
              ><v-icon color="primary">mdi-pencil</v-icon></v-btn
            >
            <v-btn icon @click.stop="confirmerSuppression(item.id)"
              ><v-icon color="red">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
        <v-btn
          large
          bottom
          color="accent"
          dark
          fab
          fixed
          right
          @click="
            $router.push({
              name: 'patients.create',
              params: {
                mod: 'create',
              },
            })
          "
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-container>
    </v-col>
    <v-dialog v-model="confirmerSupp" persistent max-width="490">
      <v-card>
        <v-card-title class="headline">Confirmer la suppression ?</v-card-title>
        <v-card-text>Cette action est définitive.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="confirmerSupp = false"
            >Annuler</v-btn
          >
          <v-btn color="red darken-1" text @click="supprimer">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { db } from "@/main";

export default {
  name: "Patients",
  data() {
    return {
      search: "",
      archives: false,
      alert: false,
      confirmerSupp: false,
      loading: false,
      headers: [
        {
          text: "Nom",
          align: "left",
          value: "nom",
        },
        { text: "Prénom", value: "prenom" },
        { text: "Téléphone", value: "telephone" },
        {
          text: "Dernière consultation",
          value: "derniereConsultationRendered",
        },
        {
          text: "Actions",
          value: "action",
          align: "right",
          sortable: false,
        },
      ],
      nom: "",
      prenom: "",
    };
  },
  mounted() {
    this.$emit("getTitre", "Liste des patients");
  },
  computed: {
    patients() {
      return this.$store.getters.patients;
    },
    patientsFiltres() {
      if (this.archives) return this.patients;
      return this.patients.filter((pat) => !pat.isArchive);
    },
  },
  methods: {
    goToPatient(patient) {
      this.$router.push({
        name: "patients.show",
        params: {
          id: patient.id,
        },
      });
    },
    editPatient(id) {
      this.$router.push({
        name: "patients.edit",
        params: {
          id: id,
        },
      });
    },
    confirmerSuppression(id) {
      this.patient_a_supprimer = id;
      this.confirmerSupp = true;
    },
    async archiver(id) {
      await db.collection("patients").doc(id).update({ isArchive: true });
    },
    async desarchiver(id) {
      await db.collection("patients").doc(id).update({ isArchive: false });
    },
    async supprimer() {
      this.loading = true;
      this.confirmerSupp = false;
      await db.collection("patients").doc(this.patient_a_supprimer).delete();
      this.alert = true;
      this.loading = false;
    },
  },
};
</script>

<style>
table tr {
  cursor: pointer;
}
</style>


