<template>
  <v-container fluid class="pb-8">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <form @submit.prevent="valider">
      <!-- Infos générales  -->
      <h2>
        <v-icon color="primary" left large>mdi-information</v-icon>Infos
        générales
      </h2>
      <v-row class="mt-5">
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="Date de visite"
            outlined
            required
            rounded
            v-model="form.date_de_visite"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="Nom"
            outlined
            required
            v-model="form.nom"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="Prénom"
            outlined
            required
            v-model="form.prenom"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-select
            :items="['Femme', 'Homme', 'Enfant fille', 'Enfant garçon']"
            label="Sexe"
            outlined
            rounded
            required
            v-model="form.sexe"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            label="Adresse"
            outlined
            required
            v-model="form.adresse"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="Code postal"
            outlined
            required
            v-model="form.code_postal"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="Ville"
            outlined
            required
            v-model="form.ville"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="Date de naissance"
            outlined
            required
            rounded
            v-model="form.date_de_naissance"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="Age"
            outlined
            disabled
            rounded
            v-model="age"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            type="email"
            label="Email"
            outlined
            required
            v-model="form.email"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="Numéro de téléphone"
            outlined
            required
            v-model="form.telephone"
            rounded
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="9">
          <v-text-field
            label="Motif de consultation"
            outlined
            v-model="form.motif_de_consultation"
            rounded
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="Déjà consulté une diet ?"
            outlined
            v-model="form.deja_consulte_diet"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- / fin infos générales  -->

      <!-- situation familiale et pro  -->
      <h2 class="mb-5">
        <v-icon large left color="primary">mdi-human-female-girl</v-icon
        >Situation familiale et professionnelle
      </h2>
      <v-row>
        <v-col cols="12" sm="6" lg="6" v-if="isEnfant">
          <v-text-field
            label="Classe ou niveau scolaire"
            outlined
            v-model="form.classe"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3" v-if="!isEnfant">
          <v-combobox
            v-model="form.situation_de_famille"
            :items="['Seul', 'En couple']"
            label="Situation de famille"
            outlined
            rounded
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="6" lg="3" v-if="!isEnfant">
          <v-text-field
            label="Nombre d'enfants ?"
            type="number"
            outlined
            v-model="form.enfants"
            rounded
          ></v-text-field>
        </v-col>
        <v-fade-transition mode="out-in">
          <v-col cols="12" sm="6" lg="3" v-if="!isEnfant">
            <v-text-field
              label="Age des enfants"
              outlined
              v-model="form.age_enfants"
              rounded
              v-show="montrer_enfants"
            ></v-text-field>
          </v-col>
        </v-fade-transition>
        <v-col cols="12" sm="6" lg="3" v-if="!isEnfant">
          <v-text-field
            label="Combien à charge ?"
            outlined
            v-model="form.enfants_a_charge"
            rounded
            v-show="montrer_enfants"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3" v-if="!isEnfant">
          <v-autocomplete
            label="Grossesse/ménopause"
            :items="['Grossesse', 'Ménopause', 'Non']"
            v-model="form.grossesse_menopause"
            outlined
            rounded
            v-if="form.sexe == 'Femme'"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" lg="3" v-if="!isEnfant">
          <v-text-field
            label="Profession"
            outlined
            v-model="form.profession"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3" v-if="!isEnfant">
          <v-text-field
            label="Horaires de travail"
            outlined
            v-model="form.horaires_de_travail"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- / fin situation familiale et professionnelle -->

      <!-- Activité physique  -->
      <h2 class="mb-5">
        <v-icon large left color="primary">mdi-weight-lifter</v-icon>Activité
        sportive
      </h2>
      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <v-select
            :items="['Aucune', 'Moins de 30 min', 'Plus de 30 min']"
            label="Activité physique par jour"
            outlined
            rounded
            v-model="form.activite_physique"
          ></v-select>
        </v-col>
        <v-col cols="12" md="9">
          <v-text-field
            label="Pratique sportive"
            outlined
            rounded
            v-model="form.sport"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- / fin activité physique -->

      <!-- santé générale  -->
      <h2 class="mb-5">
        <v-icon large left color="primary">mdi-bottle-tonic-plus</v-icon>Santé
        générale
      </h2>
      <v-row>
        <v-col cols="12" sm="6" lg="6">
          <v-combobox
            v-model="form.transit"
            :items="['Bon', 'Constipation', 'Diarhées', 'Ballonnements']"
            label="Qualité du transit"
            outlined
            rounded
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            label="Douleurs"
            outlined
            v-model="form.douleurs"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- / fin santé générale  -->

      <!-- situation médicale -->
      <h2 class="mb-5">
        <v-icon large left color="primary">mdi-doctor</v-icon>Situation médicale
      </h2>
      <v-row>
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            label="Problèmes de santé ?"
            outlined
            v-model="form.problemes_sante"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            label="Quels médicaments ?"
            outlined
            v-model="form.medicaments"
            rounded
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="6"
          v-if="form.sexe === 'Femme' || this.form.sexe === 'Enfant fille'"
        >
          <v-text-field
            label="Contraception"
            outlined
            v-model="form.contraception"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            label="Antécédents familiaux (AVC, cancer...)"
            outlined
            v-model="form.antecedents_familiaux"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Prise de sang ?"
            outlined
            v-model="form.prise_de_sang"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Opérations ?"
            outlined
            v-model="form.operations"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- / fin situation médicale  -->

      <!-- addictions -->
      <h2 class="mb-5">
        <v-icon large left color="primary">mdi-smoking</v-icon>Addictions
      </h2>
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Tabac ?"
            outlined
            v-model="form.tabac"
            rounded
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Alcool consommé ? Quantité et fréquence"
            outlined
            v-model="form.alcool"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- / fin addictions  -->

      <!--     Habitudes alimentaires -->
      <h2 class="mb-5">
        <v-icon large left color="primary">mdi-food-variant</v-icon>Journée type
      </h2>
      <v-row>
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="form.petit_dejeuner"
            label="Petit déjeuner"
            placeholder="Contenu et lieu"
            rows="3"
            rounded
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="form.collation"
            label="Collation de 10h"
            placeholder="Contenu et lieu"
            rows="3"
            rounded
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="form.dejeuner"
            label="Déjeuner"
            placeholder="Contenu et lieu"
            rows="3"
            rounded
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="form.gouter"
            label="Goûter"
            placeholder="Contenu et lieu"
            rows="3"
            rounded
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="form.diner"
            label="Diner"
            placeholder="Contenu et lieu"
            rows="3"
            rounded
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="form.collation_apres_diner"
            label="Collation après le diner"
            placeholder="Contenu"
            rows="3"
            rounded
          ></v-textarea>
        </v-col>
      </v-row>
      <!-- / fin habitudes alimentaires  -->

      <!--     Fréquence alimentaires -->
      <h2 class="mb-5">
        <v-icon large left color="primary">mdi-food-apple</v-icon>Fréquence
        alimentaire
      </h2>
      <v-row>
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            label="Légumes secs ?"
            outlined
            v-model="form.legumes_secs"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            label="Cuisine à l'huile ou au beurre ?"
            outlined
            v-model="form.cuisine_huile_ou_beurre"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Repas plaisirs ?"
            outlined
            v-model="form.repas_plaisir"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- / fin fréquence alimentaires  -->

      <!--     Boissons ******** -->
      <h2 class="mb-5">
        <v-icon large left color="primary">mdi-glass-tulip</v-icon>Boissons
      </h2>
      <v-row>
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            label="Eau (fréquence et quantité)"
            outlined
            v-model="form.eau"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            label="Boissons sucrées (fréquence et quantité)"
            outlined
            v-model="form.boissons_sucrees"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            label="Café (fréquence et quantité)"
            outlined
            v-model="form.cafe"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            label="Thé (fréquence et quantité)"
            outlined
            v-model="form.the"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- / fin boisons ****** -->

      <!-- / fin sel ****** -->

      <!--     Comportement alimentaire ******** -->
      <h2 class="mb-5">
        <v-icon large left color="primary">mdi-stove</v-icon>Comportement
        alimentaire
      </h2>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            label="Salez-vous vos repas ?"
            outlined
            v-model="form.salage_des_repas"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Allergies ?"
            outlined
            v-model="form.allergies"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-switch
            v-model="form.balance"
            label="Balance de cuisine"
          ></v-switch>
        </v-col>
      </v-row>
      <!--  / fin comportement alimentaire -->

      <!-- Mesures anthropométriques  -->
      <h2 class="mb-5">
        <v-icon large left color="primary">mdi-tape-measure</v-icon>Mesures
        anthropométriques
      </h2>
      <v-row>
        <v-col cols="12" sm="6" lg="2">
          <v-text-field
            label="Poids actuel"
            type="number"
            step="0.001"
            outlined
            required
            v-model="form.poids"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="2">
          <v-text-field
            label="Poids souhaité"
            step="0.001"
            outlined
            v-model="form.poids_souhaite"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="2">
          <v-text-field
            label="Taille en cm"
            type="number"
            outlined
            required
            v-model="form.taille"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="2">
          <v-text-field
            label="IMC"
            outlined
            readonly
            v-model="imc"
            rounded
            prepend-inner-icon="mdi-scale"
            :background-color="couleur_imc"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="2">
          <v-text-field
            label="Poids idéal"
            outlined
            readonly
            v-model="poids_ideal"
            rounded
            prepend-inner-icon="mdi-weight-kilogram"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="% graisse"
            outlined
            v-model="form.pourcentage_de_graisse"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="% muscle"
            outlined
            v-model="form.pourcentage_de_muscle"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="% eau"
            outlined
            v-model="form.pourcentage_deau"
            rounded
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <v-text-field
            label="Graisse viscerale"
            outlined
            v-model="form.graisse_viscerale"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- / fin mesures anthropométriques  -->

      <!-- debut bilan -->
      <h2 class="mb-5">
        <v-icon large left color="primary">mdi-arm-flex</v-icon>Bilan et
        objectifs
      </h2>
      <v-row>
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="form.bilan"
            label="Bilan"
            rows="4"
            rounded
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="form.objectifs"
            label="Objectifs sur l'application"
            rows="4"
            rounded
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Comment m'avez-vous connue ?"
            outlined
            v-model="form.connu_comment"
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- / fin bilan  -->

      <!-- Les 2 fab en bas *********************************************************  -->
      <div class="fab1">
        <v-btn color="success" dark fab type="submit" large>
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
      <div class="fab2" v-if="mode === 'edit'">
        <v-btn
          dark
          fab
          large
          @click.prevent="
            $router.push({
              name: 'patients.show',
              params: {
                id: $route.params.id,
              },
            })
          "
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <!-- / fin des 2 fabs  -->
    </form>
  </v-container>
</template>

<script>
import { db } from "@/main";
import dayjs from "dayjs";

export default {
  data: () => ({
    patient: {},
    loading: false,
    form: {
      // infos générales
      nom: "",
      prenom: "",
      date_de_naissance: "",
      date_de_visite: dayjs().format("YYYY-MM-DD"),
      email: "",
      adresse: "",
      code_postal: "",
      ville: "",
      sexe: "Femme",
      telephone: "",
      type: "bilan",
      motif_de_consultation: "",
      deja_consulte_diet: "",
      // situation familiale et pro
      situation_de_famille: "",
      enfants: "",
      age_enfants: "",
      enfants_a_charge: "",
      grossesse_menopause: "",
      profession: "",
      horaires_de_travail: "",
      classe: "",
      // activité physique
      activite_physique: "",
      sport: "",
      // Santé générale
      transit: "",
      douleurs: "",
      problemes_sante: "",
      medicaments: "",
      contraception: "",
      antecedents_familiaux: "",
      prise_de_sang: "",
      operations: "",
      // addictions
      alcool: "",
      // journée type
      petit_dejeuner: "",
      collation: "",
      dejeuner: "",
      gouter: "",
      diner: "",
      collation_apres_diner: "",
      // fréquence alimentaire
      legumes_secs: "",
      cuisine_huile_ou_beurre: "",
      repas_plaisir: "",
      // boissons
      eau: "",
      boissons_sucrees: "",
      cafe: "",
      the: "",
      // sel
      ressalage_systematique: 0,
      salage_des_repas: "",
      // comportement alimentaire
      balance: false,
      allergies: "",
      // mesure anthropométriques
      poids: "",
      poids_souhaite: "",
      taille: "",
      pourcentage_de_graisse: "",
      graisse_viscerale: "",
      pourcentage_de_muscle: "",
      pourcentage_deau: "",
      // bilan
      bilan: "",
      objectifs: "",
      connu_comment: "",
    },
  }),
  props: {
    mode: String,
  },
  mounted() {
    if (this.mode == "edit") {
      this.getPatient();
    }
  },

  computed: {
    isEnfant() {
      return (
        this.form.sexe === "Enfant fille" || this.form.sexe === "Enfant garçon"
      );
    },
    montrer_enfants() {
      if (this.form.enfants > 0) {
        return true;
      }
      return false;
    },
    imc() {
      if (this.form.poids > 0 && this.form.taille > 0) {
        let imc = this.form.poids / Math.pow(this.form.taille / 100, 2) || 0;
        return Math.round(imc * 100) / 100;
      }
      return "";
    },
    couleur_imc() {
      if (this.imc >= 25 && this.imc < 30) {
        return "red lighten-5";
      } else if (this.imc >= 30 && this.imc < 199) {
        return "red lighten-4";
      } else if (this.imc >= 18.5 && this.imc < 25) {
        return "green lighten-4";
      } else if (this.imc > 1 && this.imc < 18.5) {
        return "orange lighten-3";
      }
      return "";
    },
    poids_ideal() {
      if (this.form.sexe == "Femme" || this.form.sexe == "Enfant fille") {
        return this.form.taille - 100 - (this.form.taille - 150) / 2.5;
      } else {
        return this.form.taille - 100 - (this.form.taille - 150) / 4;
      }
    },
    age() {
      if (this.form.date_de_naissance != "") {
        return dayjs().diff(dayjs(this.form.date_de_naissance), "years");
      }
      return "";
    },
  },
  methods: {
    // récupérer le patient si c'est une modif
    async getPatient() {
      this.loading = true;
      this.patient = this.$store.state.patients.filter(
        (e) => e.id == this.$route.params.id
      )[0];
      Object.assign(this.form, this.patient);
      this.form.date_de_visite = dayjs(
        this.patient.date_de_visite.toDate()
      ).format("YYYY-MM-DD");
      this.loading = false;
    },
    // valider le formulaire
    async valider() {
      this.loading = true;
      // inclure le poids idéal
      let formattedForm = { ...this.form };
      formattedForm.poids_ideal = this.poids_ideal;
      formattedForm.poids = Number(this.form.poids);
      formattedForm.imc = this.imc;
      formattedForm.date_de_visite = dayjs(this.form.date_de_visite).toDate();
      if (this.mode == "create") {
        console.log("Lancement de la création");
        // création : stocker le patient et la visite
        let save = await db.collection("patients").add(formattedForm);
        this.form.id = save.id;
        this.loading = false;
        this.$emit("quitter");
        this.$router.push({
          name: "facture",
          params: {
            id: save.id,
            type: "bilan",
          },
        });
      } else if (this.mode == "edit") {
        console.log("Lancement de l'update.");

        // modification : modif du patient et du bilan
        await db
          .collection("patients")
          .doc(this.$route.params.id)
          .update(formattedForm);
        console.log("Fin de l'update");
        this.loading = false;
        this.$router.push({
          name: "patients",
        });
      }
    },
  },
};
</script>
