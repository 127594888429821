<script>
import { Line, mixins } from "vue-chartjs";

export default {
  extends: Line,
  props: ["chartData", "options"],
  mixins: [mixins.reactiveProp],
  mounted() {
    this.$watch(
      "chartData",
      (newVal, oldVal) => {
        if (!oldVal) {
          this.renderChart(this.chartData, this.options);
        }
      },
      { immediate: true }
    );
  },
};
</script>
