<template>
   <div>
      <FormulaireBilan mode="edit" />
   </div>
</template>

<script>
import FormulaireBilan from "@/components/FormulaireBilan";

export default {
   components: { FormulaireBilan },

   created() {
      this.$emit("getTitre", "Modifier un patient");
   }
};
</script>

<style>
</style>