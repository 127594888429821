<template>
  <div class="pa-2">
    <v-row>
      <v-col
        cols="12"
        lg="4"
        md="6"
        v-for="recette in recettes"
        :key="recette.id"
      >
        <v-card>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline font-weight-bold"
                ><span style="font-size: 1.2rem; line-height: 1.4rem">{{
                  recette.titre
                }}</span></v-card-title
              >

              <v-card-subtitle
                v-text="recette.temps + ' min'"
              ></v-card-subtitle>

              <v-card-actions>
                <v-btn
                  class="ml-2 mt-5"
                  rounded
                  color="orange"
                  dark
                  depressed
                  small
                  @click="editRecette(recette)"
                  >Modif.</v-btn
                >
                <v-btn
                  class="ml-2 mt-5"
                  rounded
                  small
                  depressed
                  dark
                  color="red"
                  @click="confirmer(recette.id)"
                  >Suppr.</v-btn
                >
              </v-card-actions>
            </div>

            <v-avatar class="ma-3" size="125" tile>
              <v-img :src="recette.photoUrl"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-dialog v-model="confirmerSupp" persistent max-width="490">
        <v-card>
          <v-card-title class="headline"
            >Confirmer la suppression ?</v-card-title
          >
          <v-card-text>Cette action est définitive.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="confirmerSupp = false"
              >Annuler</v-btn
            >
            <v-btn color="red darken-1" text @click="deleteRecette"
              >Confirmer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- FAB  -->
    <v-btn
      large
      bottom
      color="green"
      dark
      fab
      fixed
      right
      @click="
        $router.push({
          name: 'recettes.create',
          params: {
            mod: 'create',
          },
        })
      "
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <!-- / FAB  -->
  </div>
</template>

<script lang="ts">
import { db } from "../main";
export default {
  data() {
    return {
      confirmerSupp: false,
      selectedRecetteId: null
    };
  },
  computed: {
    recettes() {
      return this.$store.state.recettes;
    }
  },
  mounted() {
    this.$store.dispatch("bindRecettes");
  },
  methods: {
    confirmer(id) {
      this.selectedRecetteId = id;
      this.confirmerSupp = true;
    },
    editRecette(recette) {
      this.$router.push({
        name: "recettes.edit",
        params: {
          recette: recette
        }
      });
    },
    async deleteRecette() {
      db.collection("recettes")
        .doc(this.selectedRecetteId)
        .delete();
      this.confirmerSupp = false;
      this.selectedRecetteId = null;
    }
  }
};
</script>
