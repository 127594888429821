<template>
   <div>
      <formulaire-suivi mode="create"></formulaire-suivi>
   </div>
</template>

<script>
   import FormulaireSuivi from "@/components/FormulaireSuivi";

   export default {
      data: () => ({}),
      components: { FormulaireSuivi },
      mounted() {
         this.$emit("getTitre", "Nouvelle consultation de suivi");
      }
   };
</script>

<style>
</style>
