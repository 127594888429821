<template>
  <div class="pa-4">
    <h1 class="mb-4">Nouvelle recette</h1>
    <RecetteFormulaire mode="create" />
  </div>
</template>

<script>
import RecetteFormulaire from "./RecetteFormulaire";

export default {
  components: {
    RecetteFormulaire,
  },
};
</script>
