<template>
  <div class="pa-2">
    <v-row>
      <v-col cols="12" lg="4" md="6">
        <div class="pa-2">
          <v-card :loading="loading_mois">
            <v-toolbar color="orange" dark flat>
              <v-toolbar-title>Consultations bilan par mois</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <h3 v-for="stat in statsMoisPrecedents" :key="stat.nom">
                {{ stat.nom.toUpperCase() }} : {{ stat.bilan }}
              </h3>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" lg="4" md="6">
        <div class="pa-2">
          <v-card :loading="loading_age">
            <v-toolbar color="pink" dark flat>
              <v-toolbar-title>Age moyen</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-container text-center>
                <h2>{{ age_moyen }}</h2>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" lg="4" md="6">
        <div class="pa-2">
          <v-card :loading="loading_stats">
            <v-toolbar color="teal" dark flat>
              <v-toolbar-title>Pourcentage de réussite</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-container text-center>
                <h2 v-if="showPourcentage">{{ pourcentage_de_reussite }}</h2>
                <v-btn v-else color="teal" dark @click="getPourcentage"
                  >Dévoiler</v-btn
                >
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db } from "@/main";
import axios from "axios";

export default {
  data() {
    return {
      loading_stats: null,
      loading_mois: null,
      succes: 0,
      nombre_de_patients: 0,
      statsMoisPrecedents: [],
      showPourcentage: false,
    };
  },
  mounted() {
    this.$emit("getTitre", "Statistiques");
    this.getStatsMoisPrecedents();
  },
  computed: {
    patients() {
      return this.$store.getters.patients;
    },
    pourcentage_de_reussite() {
      if (this.nombre_de_patients > 0) {
        return Math.round((100 * this.succes) / this.nombre_de_patients) + " %";
      }
      return "Chargement ...";
    },
    loading_age() {
      if (this.patients.length == 0) {
        return "pink";
      }
      return false;
    },
    age_moyen() {
      if (this.patients.length > 0) {
        let age = 0;
        let i = 0;
        this.patients.forEach((pat) => {
          age += pat.age;
          i++;
        });
        return Math.round(age / i) + " ans";
      }
      return "Chargement ...";
    },
  },
  methods: {
    async getStatsMoisPrecedents() {
      this.loading_mois = "orange";
      let res = await axios.get(
        "https://europe-west1-maurine.cloudfunctions.net/getDerniersMois"
      );
      this.statsMoisPrecedents = res.data;

      this.loading_mois = false;
    },
    async getPourcentage() {
      if (this.patients.length == 0) {
        return;
      }

      this.loading_stats = "teal";
      this.showPourcentage = true;

      this.patients.forEach(async (pat) => {
        let id = pat.id;
        let visites = [];
        // requete
        let res = await db
          .collection("patients")
          .doc(id)
          .collection("consultations")
          .orderBy("date", "desc")
          .limit(1)
          .get();

        // circulation sous doc
        res.forEach((doc) => {
          let data = doc.data();
          visites.push(data);
        });

        if (visites.length > 0) {
          if (Number(visites[0].poids) > 0) {
            this.nombre_de_patients++;
            if (Number(visites[0].poids) < Number(pat.poids)) {
              this.succes++;
            }
          }
        }
        this.loading_stats = false;
      });
    },
  },
};
</script>
