<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <form @submit.prevent="valider">
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Titre"
            outlined
            required
            v-model="form.titre"
            rounded
            background-color="white"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3"
          ><v-select
            :items="typeItems"
            label="Type de plat"
            outlined
            background-color="white"
            rounded
            required
            v-model="form.type"
          ></v-select
        ></v-col>
        <v-col cols="12" md="3">
          <v-text-field
            label="Temps nécessaire (minutes)"
            outlined
            required
            background-color="white"
            v-model.number="form.temps"
            type="number"
            step="1"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2"
          ><v-select
            :items="difficulteItems"
            label="Difficulté"
            outlined
            rounded
            background-color="white"
            required
            v-model="form.difficulte"
          ></v-select
        ></v-col>
        <v-col cols="12" md="2"
          ><v-select
            :items="coutItems"
            label="Coût"
            background-color="white"
            outlined
            rounded
            required
            v-model="form.cout"
          ></v-select
        ></v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Personnes"
            outlined
            required
            background-color="white"
            v-model.number="form.personnes"
            type="number"
            step="1"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            background-color="white"
            outlined
            rounded
            required
            label="Ingrédients"
            v-model="form.ingredients"
          >
          </v-textarea
        ></v-col>
        <v-col cols="12">
          <v-textarea
            background-color="white"
            outlined
            rounded
            required
            label="Recette"
            v-model="form.recette"
            rows="12"
          >
          </v-textarea
        ></v-col>
        <v-col cols="12">
          <v-file-input
            v-model="photo"
            accept="image/*"
            rounded
            :required="mode == 'create'"
            background-color="white"
            outlined
            label="Selectionner un fichier"
          ></v-file-input
        ></v-col>
      </v-row>
      <v-row>
        <v-btn
          dark
          color="grey darken-1"
          @click="$router.push({ name: 'recettes' })"
          class="pa-5 ma-4"
          :disabled="loading"
        >
          Retour
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          dark
          color="green darken-1"
          class="pa-5 ma-4"
          :disabled="loading"
        >
          Valider
        </v-btn>
      </v-row>
    </form>
  </div>
</template>

<script>
import { db, storage } from "../../main";
import imageCompression from "browser-image-compression";

export default {
  props: {
    mode: String,
    recette: Object,
  },
  data() {
    return {
      typeItems: [
        { text: "Entrée", value: 0 },
        { text: "Plat", value: 1 },
        { text: "Dessert", value: 2 },
        { text: "Recettes plaisir", value: 3 },
        { text: "Vos recettes", value: 4 },
      ],
      coutItems: [
        { text: "€", value: 0 },
        { text: "€€", value: 1 },
        { text: "€€€", value: 2 },
      ],
      difficulteItems: [
        { text: "Facile", value: 0 },
        { text: "Intermédiaire", value: 1 },
        { text: "Difficile", value: 2 },
      ],
      loading: false,
      photo: null,
      form: {
        titre: "",
        ingredients: "",
        temps: null,
        photoUrl: "",
        difficulte: 1,
        cout: 1,
        recette: "",
        type: null,
        personnes: 2,
      },
    };
  },
  mounted() {
    if (this.mode == "edit") {
      this.loadRecette();
    }
  },
  methods: {
    loadRecette() {
      this.form.titre = this.recette.titre;
      this.form.ingredients = this.recette.ingredients;
      this.form.temps = this.recette.temps;
      this.form.difficulte = this.recette.difficulte;
      this.form.cout = this.recette.cout;
      this.form.photoUrl = this.recette.photoUrl;
      this.form.photoPath = this.recette.photoPath;
      this.form.recette = this.recette.recette;
      this.form.type = this.recette.type;
      this.form.personnes = this.recette.personnes;
    },
    async valider() {
      this.loading = true;

      // Upload la photo si necessaire
      if (this.photo != null) {
        if (this.mode == "edit") {
          const httpsReference = storage.refFromURL(this.form.photoUrl);
          httpsReference.delete();
        }
        await this.uploadPhoto();
      }

      // Créer ou update recette
      if (this.mode == "create") {
        await db.collection("recettes").add(this.form);
      } else {
        await db.collection("recettes").doc(this.recette.id).update(this.form);
      }

      this.loading = false;
      this.$router.push({ name: "recettes" });
    },
    async uploadPhoto() {
      let vm = this;

      try {
        let storageRef = storage.ref();
        let timestamp = Number(new Date());
        let fileName = timestamp.toString();
        const path = "photos_recettes/" + fileName + ".jpg";
        vm.form.photoPath = path;
        storageRef = storage.ref().child(path);
        // compresser la photo
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 550,
          useWebWorker: true,
        };
        const compressedImage = await imageCompression(vm.photo, options);
        await storageRef.put(compressedImage);
        await storageRef
          .getDownloadURL()
          .then((res) => (vm.form.photoUrl = res));
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
