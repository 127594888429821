<template>
  <div class="pa-3">
    <div v-if="repas.length === 0">Aucun repas</div>
    <div v-for="(jour, i) in repas" :key="i">
      <h2>{{ jour.date }}</h2>
      <v-row>
        <v-col cols="12" md="6" lg="4" v-for="r in jour.repas" :key="r.id">
          <RepasItem :r="r" />
        </v-col>
      </v-row>
      <v-divider class="my-10"></v-divider>
    </div>
  </div>
</template>

<script lang="ts">
import { db } from "../../main";
import dayjs from "dayjs";
import RepasItem from "./RepasItem.vue";

export default {
  components: {
    RepasItem,
  },
  data() {
    return {
      repas: [],
    };
  },
  computed: {
    patient() {
      return this.$store.state.patients.find(
        (e) => e.userId == this.$route.params.id
      );
    },
  },
  mounted() {
    this.$emit("getTitre", "Journal de repas");
    this.getRepas();
  },
  methods: {
    async getRepas() {
      const snaps = await db
        .collection("repas")
        .where("userId", "==", this.$route.params.id)
        .orderBy("heure")
        .get();
      let repas = snaps.docs.map((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.date = data.date.toDate();
        return data;
      });

      // let jour = dayjs().subtract(30, 'day');
      let jour = dayjs();
      let res = [];

      // while (jour.isBefore(dayjs())) {
      //   let repasDuJour = repas.filter(r => dayjs(r.date).isSame(jour, "day"));
      //   if (repasDuJour.length > 0) {
      //     res.push({date: jour.format("DD/MM/YYYY"), repas: repasDuJour,});
      //     // repas = repas.filter(r => !dayjs(r.date).isSame(jour, "date"));
      //   }

      //   jour = jour.add(1, 'day');
      // }

      while (repas.length > 0 || jour.isAfter(dayjs().subtract(150, "day"))) {
        let repasDuJour = repas.filter((r) =>
          dayjs(r.date).isSame(jour, "day")
        );
        if (repasDuJour.length > 0) {
          res.push({ date: jour.format("DD/MM/YYYY"), repas: repasDuJour });
          repas = repas.filter((r) => !dayjs(r.date).isSame(jour, "date"));
        }

        jour = jour.subtract(1, "day");
      }

      this.repas = res;
    },
  },
};
</script>
