<template>
  <div class="pa-4">
    <h1 class="mb-4">Modifier la recette</h1>
    <RecetteFormulaire mode="edit" :recette="$route.params.recette" />
  </div>
</template>

<script>
import RecetteFormulaire from "./RecetteFormulaire";

export default {
  components: {
    RecetteFormulaire,
  },
};
</script>
