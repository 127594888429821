<template>
  <div>
    <v-alert type="error" v-if="error"
      >Une erreur s'est produite. La facture n'a été envoyée à
      personne.</v-alert
    >
    <div class="mb-2">
      <v-btn
        :disabled="loading"
        :loading="loading"
        depressed
        large
        color="primary"
        @click="envoyerFacture"
        >Envoyer la facture</v-btn
      >
      <router-link
        :to="{
          name: 'patients.show',
          params: {
            id: $route.params.id,
          },
        }"
      >
        <v-btn
          :disabled="loading"
          :loading="loading"
          depressed
          large
          class="ml-3"
          >Passer</v-btn
        >
      </router-link>
    </div>
  </div>
  <!-- template  -->
</template>

<script>
import { functions } from "@/main";

export default {
  name: "Facture",
  data: () => ({
    loading: false,
    error: false,
  }),
  props: {
    patient: Object,
    prix: String,
    date: String,
  },

  methods: {
    async envoyerFacture() {
      this.loading = true;
      this.error = false;
      const envoyerFacture = functions.httpsCallable("factures-envoyerFacture");
      try {
        await envoyerFacture({
          patientId: this.$route.params.id,
          prix: this.prix,
          email: this.patient.email,
          date: this.date,
        });

        this.$router.push({
          name: "patients.show",
          params: { id: this.$route.params.id },
        });
      } catch (error) {
        console.log(error);
        this.error = true;
        this.loading = false;
      }
    },
  },
};
</script>
