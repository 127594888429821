<template>
  <div>
    <v-card class="mt-5">
      <div v-if="photos.length <= 1">
        <v-img
          v-if="photos.length == 1"
          :src="
            photos[0] || 'https://via.placeholder.com/350?text=Pas+de+photos'
          "
          height="350px"
          @click="loadLightbox(photos, 0)"
          style="cursor: pointer"
        ></v-img>
        <v-img
          v-else
          src="https://via.placeholder.com/350?text=Pas+de+photos"
          height="350px"
        ></v-img>
      </div>
      <v-row class="ma-0 pa-0" v-else>
        <v-col
          cols="6"
          v-for="(photo, index) in photos"
          :key="index"
          @click="loadLightbox(photos, index)"
        >
          <v-img :src="photo" height="350px" style="cursor: pointer"> </v-img>
        </v-col>
      </v-row>

      <v-card-title
        >{{ r.nom
        }}<span class="font-italic">{{ heure(r) }}</span></v-card-title
      >
      <v-card-subtitle>
        <strong>Sensation de faim : </strong
        >{{ getSensationDeFaim(r.sensationDeFaim) }}
      </v-card-subtitle>
      <v-card-text>{{ r.description }}</v-card-text>
    </v-card>
    <CoolLightBox :items="lightboxItems" :index="index" @close="fermer">
    </CoolLightBox>
  </div>
</template>

<script>
import { db } from "../../main";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    CoolLightBox,
  },
  props: {
    r: Object,
  },
  data() {
    return {
      index: null,
      lightboxItems: [],
      photos: [],
      loading: false,
    };
  },
  mounted() {
    this.getPhotos();
  },
  methods: {
    loadLightbox(photos, index) {
      this.lightboxItems = photos;
      this.index = index;
    },
    fermer() {
      this.index = null;
      this.lightboxItems = [];
    },
    heure(repas) {
      if (repas.heure || repas.minute) {
        if (repas.minute.toString().length == 1)
          repas.minute = "0" + repas.minute;
        return " - " + repas.heure + "h" + repas.minute;
      }
      return "";
    },
    getSensationDeFaim(i) {
      if (i == 0) return "Pas faim";
      if (i == 0.5) return "Moyennement faim";
      if (i == 1) return "Faim";
      return "Très faim";
    },
    async getPhotos() {
      this.loading = true;

      if (this.r.photoPath && this.r.photoUrl) {
        this.photos.push(this.r.photoUrl);
      }
      const res = await db
        .collection("photos")
        .where("repasId", "==", this.r.id)
        .get();
      res.docs.forEach((doc) => {
        const data = doc.data();
        this.photos.push(data.url);
      });

      this.loading = false;
    },
  },
};
</script>

<style>
</style>
