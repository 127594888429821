<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="!loading">
      <v-row>
        <v-col cols="12" md="6">
          <h1>
            {{ patient.nom + " " + patient.prenom }} -
            <i>{{ age }} ans</i>
          </h1>
        </v-col>
        <v-col cols="12" md="6" class="text-right hidden-sm-and-down">
          <!-- Journal de repas -->
          <router-link
            :to="{ name: 'repas', params: { id: patient.userId } }"
            target="_blank"
            v-if="patient.userId"
          >
            <v-btn color="green" dark class="mr-1">journal de repas</v-btn>
          </router-link>
          <v-btn
            v-else
            color="green"
            dark
            class="mr-1"
            :loading="loadingCompte"
            @click="creerCompte"
            >Créer compte appli</v-btn
          >
          <router-link
            :to="{ name: 'patients.edit', params: { id: patient.id } }"
            target="_blank"
          >
            <v-btn>
              Consultation bilan
              <v-icon class="ml-2">mdi-open-in-new</v-icon>
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
      <div class="separateur"></div>
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <strong>Motif de consultation</strong>
          <br />
          {{ patient.motif_de_consultation }}
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <strong>Poids de départ</strong>
          <br />
          {{ patient.poids }} KG
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <strong>Poids souhaité</strong>
          <br />
          {{ patient.poids_souhaite }} KG
        </v-col>
        <v-col cols="12" lg="4" v-if="mode == 'create'">
          <strong>Date de dernière consultation</strong>
          <br />
          {{ date_derniere_consu || patient.date }}
        </v-col>
        <v-col cols="12" lg="4">
          <strong>Bilan de la dernière consultation</strong>
          <br />
          {{ dernier_objectif }}
        </v-col>
        <v-col cols="12" lg="4">
          <strong>Graisse viscerale</strong>
          <br />
          {{
            derniere_consultation.graisse_viscerale || patient.graisse_viscerale
          }}
        </v-col>
        <v-col cols="12" lg="4">
          <strong>Pourcentage graisse</strong>
          <br />
          {{
            derniere_consultation.pourcentage_de_graisse ||
            patient.pourcentage_de_graisse
          }}
        </v-col>
        <v-col cols="12" lg="4">
          <strong>Pourcentage muscle</strong>
          <br />
          {{
            derniere_consultation.pourcentage_de_muscle ||
            patient.pourcentage_de_muscle
          }}
        </v-col>
        <v-col cols="12" lg="4">
          <strong>Pourcentage d'eau</strong>
          <br />
          {{
            derniere_consultation.pourcentage_deau || patient.pourcentage_deau
          }}
        </v-col>
        <v-col cols="12" lg="4">
          <strong>IMC</strong>
          <br />
          {{ derniere_consultation.imc || patient.imc }}
        </v-col>
      </v-row>
      <h1 class="mt-9">Suivi</h1>
      <div class="separateur mt-4"></div>
      <form @submit.prevent="valider">
        <!-- formulaire ici  -->
        <v-row>
          <v-col cols="12" md="4">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Date de consultation"
                  outlined
                  type="date"
                  required
                  v-model="form.date"
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Nouveau poids"
                  autocomplete="false"
                  outlined
                  type="number"
                  step="0.001"
                  required
                  v-model="form.poids"
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Nouvel IMC"
                  autocomplete="false"
                  outlined
                  type="number"
                  step="0.001"
                  required
                  v-model="form.imc"
                  rounded
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="8">
            <LineChart
              :chart-data="chartData"
              :options="graphPoids.options"
              :height="400"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              label="% graisse"
              outlined
              v-model="form.pourcentage_de_graisse"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              label="% muscle"
              outlined
              v-model="form.pourcentage_de_muscle"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              label="% eau"
              outlined
              v-model="form.pourcentage_deau"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              label="Graisse viscerale"
              outlined
              v-model="form.graisse_viscerale"
              rounded
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              outlined
              v-model="form.bilan"
              label="Bilan"
              rows="4"
              rounded
              required
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined
              v-model="form.objectifs"
              label="Objectifs"
              rows="4"
              rounded
              required
              class="mb-10"
            ></v-textarea>
          </v-col>
        </v-row>

        <!-- Les 2 fab en bas *********************************************************  -->
        <div class="fab1">
          <v-btn color="success" dark fab type="submit" large>
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </div>

        <div class="fab2">
          <v-btn
            dark
            fab
            large
            @click.prevent="
              $router.push({
                name: 'patients.show',
                params: {
                  id: $route.params.id_patient,
                },
              })
            "
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        <!-- / fin des 2 fabs  -->
      </form>
    </div>
    <!-- snackbar copier coller  -->
    <v-snackbar v-model="showSnackbar" bottom center :timeout="2000">
      {{ message }}
      <v-btn color="accent" text @click="showSnackbar = false">Fermer</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { db, functions } from "../main";
import dayjs from "dayjs";
import LineChart from "../components/LineChart";

export default {
  name: "FormulaireSuivi",
  components: { LineChart },
  data: () => ({
    showSnackbar: false,
    message: "",
    loadingCompte: false,
    loading: true,
    patient: {},
    consultations: [],
    consultation: {},
    poids_graph: [],
    dates_graph: [],
    date_derniere_consu: "",

    graphPoids: {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barThickness: "flex",
              gridLines: {
                color: "rgba(0, 0, 0, 0.05)",
              },
            },
          ],
          yAxes: [
            {
              offset: true,
              ticks: {
                // padding: 55
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0.05)",
              },
            },
          ],
        },
      },
    },
    form: {
      graisse_viscerale: "",
      pourcentage_de_graisse: "",
      pourcentage_deau: "",
      pourcentage_de_muscle: "",
      poids: null,
      imc: "",
      bilan: "",
      objectifs: "",
      date: dayjs().format("YYYY-MM-DD"),
    },
  }),
  props: {
    mode: String,
  },
  created() {
    this.patient = this.$store.getters.patients.filter(
      (e) => e.id == this.$route.params.id_patient
    )[0];
    this.getInfos();
  },

  computed: {
    chartData() {
      let poids = [];
      let dates = [];
      if (this.mode == "create") {
        poids = [this.patient.poids, ...this.poids_graph, this.form.poids];
        dates = [
          dayjs(this.patient.date_de_visite.toDate()).format("DD/MM/YYYY"),
          ...this.dates_graph,
          dayjs().format("DD/MM/YYYY"),
        ];
      } else if (this.mode == "edit") {
        poids = [this.patient.poids, ...this.poids_graph, this.form.poids];
        dates = [
          dayjs(this.patient.date_de_visite.toDate()).format("DD/MM/YYYY"),
          ...this.dates_graph,
          dayjs(this.form.date).format("DD/MM/YYYY"),
        ];
      }

      let chartData = {
        datasets: [
          {
            label: "Poids (KG)",
            data: poids,
            borderColor: ["rgba(165, 0, 255, 0.3)"],
            pointBackgroundColor: "rgba(165, 0, 255, 1)",
            pointBorderdColor: "rgba(165, 0, 255, 1)",
            pointRadius: 5,
            pointHoverRadius: 9,
            fill: false,
          },
        ],
        labels: dates,
      };
      return chartData;
    },
    age() {
      return dayjs().diff(dayjs(this.patient.date_de_naissance), "years");
    },

    derniere_consultation() {
      if (this.consultations.length > 0) {
        return this.consultations[this.consultations.length - 1];
      }
      return this.patient.bilan;
    },

    dernier_objectif() {
      if (this.consultations.length > 0) {
        return this.consultations[this.consultations.length - 1].bilan;
      }
      return this.patient.bilan;
    },
    bej() {
      let bej;
      if (this.patient.sexe === "Femme") {
        bej =
          0.963 *
          Math.pow(this.form.poids, 0.48) *
          Math.pow(this.patient.taille / 100, 0.5) *
          Math.pow(this.age, -0.13);
      } else {
        bej =
          1.083 *
          Math.pow(this.form.poids, 0.48) *
          Math.pow(this.patient.taille / 100, 0.5) *
          Math.pow(this.age, -0.13);
      }
      return Math.round((this.form.nap * (bej * 1000)) / 4.18);
    },
    proteines() {
      return Math.round((0.13 * this.bej) / 4);
    },
    lipides() {
      return Math.round((0.35 * this.bej) / 9);
    },
    glucides() {
      return Math.round((0.52 * this.bej) / 4);
    },
  },
  methods: {
    async creerCompte() {
      this.loadingCompte = true;
      try {
        const fonctionCreer = functions.httpsCallable(
          "creerCompteManuellement"
        );
        await fonctionCreer({
          email: this.patient.email,
          password: this.patient.date_de_naissance,
          patientId: this.patient.id,
        });
        this.loadingCompte = false;
        this.toast("Compte créé !");
      } catch (error) {
        this.loadingCompte = false;
        this.toast("une erreur s'est produite.");
      }
    },
    initGraph() {
      let chartData = {
        datasets: [
          {
            label: "Poids (KG)",
            data: this.poids_pour_graph,
            borderColor: ["rgba(165, 0, 255, 1)"],
            pointBackgroundColor: "rgba(165, 0, 255, 1)",
            pointBorderdColor: "rgba(165, 0, 255, 1)",
            pointRadius: 5,
            fill: false,
          },
        ],
        labels: this.dates_pour_graph,
      };

      this.graphPoids.chartData = chartData;
    },
    valider() {
      this.loading = true;
      let saveConsultation;
      this.form.date = new Date(this.form.date);
      if (this.mode === "create") {
        saveConsultation = db
          .collection("patients")
          .doc(this.patient.id)
          .collection("consultations")
          .add(this.form);
      } else if (this.mode === "edit") {
        saveConsultation = db
          .collection("patients")
          .doc(this.patient.id)
          .collection("consultations")
          .doc(this.$route.params.id)
          .update(this.form);
      }

      Promise.all([saveConsultation]).then(() => {
        if (this.mode === "create") {
          this.$router.push({
            name: "facture",
            params: {
              id: this.patient.id,
              type: "suivi",
            },
          });
        } else {
          this.$router.push({
            name: "patients.show",
            params: {
              id: this.patient.id,
            },
          });
        }
      });
    },
    toast(message) {
      this.message = message;
      this.showSnackbar = true;
    },
    async getInfos() {
      this.loading = true;

      let snapshot_consultations = await db
        .collection("patients")
        .doc(this.$route.params.id_patient)
        .collection("consultations")
        .orderBy("date")
        .get();
      let vm = this;

      // récupérer les autres visites dans l'ordre
      snapshot_consultations.docs.forEach((doc) => {
        let consultation = doc.data();
        consultation["id"] = doc.id;
        vm.consultations.push(consultation);
        if (consultation.id != this.$route.params.id) {
          vm.poids_graph.push(consultation.poids);
          vm.dates_graph.push(
            dayjs(consultation.date.toDate()).format("DD/MM/YYYY")
          );
        }

        if (vm.mode == "edit") {
          if (doc.id == this.$route.params.id) {
            vm.form = consultation;
            vm.form.date = dayjs(consultation.date.toDate()).format(
              "YYYY-MM-DD"
            );
          }
        }
      });

      if (vm.mode == "create") {
        const taille = vm.consultations.length;

        if (taille == 0) {
          vm.form.nap = Number(vm.patient.nap);
          vm.date_derniere_consu = dayjs(
            vm.patient.date_de_visite.toDate()
          ).format("DD MMMM YYYY");
          vm.form.objectifs = vm.patient.objectifs || "";
        } else {
          let consu = vm.consultations[taille - 1];
          vm.form.nap = Number(consu.nap);
          vm.form.objectifs = consu.objectifs || "";
          vm.date_derniere_consu = dayjs(consu.date.toDate()).format(
            "DD MMMM YYYY"
          );
        }
      }

      vm.loading = false;
      vm.initGraph();
    },
  },
};
</script>

