<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="!loading">
      <v-row>
        <v-col cols="12" md="5">
          <h1>
            {{ patient.nom + " " + patient.prenom }} -
            <i>{{ age }} ans</i>
          </h1>
        </v-col>
        <v-col cols="12" md="7" class="text-right">
          <!-- photo  -->

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on }">
              <v-btn color="accent" small v-on="on">Photo</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Photo du patient</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-file-input
                      v-model="photo"
                      accept="image/*"
                      label="Selectionner un fichier"
                    ></v-file-input>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="black darken-1"
                  :disabled="loading_photo"
                  text
                  @click="dialog = false"
                  >Annuler</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  text
                  @click="uploadPhoto"
                  :disabled="photo == null"
                  :loading="loading_photo"
                  >Sauvegarder</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- fin photo  -->

          <!-- facture  -->
          <router-link
            :to="{
              name: 'facture',
              params: {
                id: $route.params.id,
                type: 'suivi',
              },
            }"
          >
            <v-btn color="error" class="ml-1 hidden-sm-and-down" small
              >Créer une facture</v-btn
            >
          </router-link>

          <!-- consu bilan  -->
          <router-link
            :to="{ name: 'patients.edit', params: { id: $route.params.id } }"
          >
            <v-btn color="secondary" small class="ml-1"
              >Consultation bilan</v-btn
            >
          </router-link>

          <!-- Journal de repas -->
          <router-link
            :to="{ name: 'repas', params: { id: patient.userId } }"
            target="_blank"
            v-if="patient.userId"
          >
            <v-btn color="green" dark class="ml-1" small
              >journal de repas</v-btn
            >
          </router-link>

          <v-btn
            v-else
            color="green"
            dark
            class="ml-1"
            small
            :loading="loadingCompte"
            @click="creerCompte"
            >Créer compte appli</v-btn
          >
        </v-col>
      </v-row>
      <div class="separateur"></div>
      <!-- photo portrait -->
      <v-row>
        <v-col cols="12" md="3">
          <!-- <v-avatar size="160"> -->
          <img
            :src="photo_url"
            class="elevation-3"
            style="max-width: 200px; max-height: 170px; border-radius: 8px"
          />
          <!-- </v-avatar> -->
        </v-col>
        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <strong>Adresse</strong>
              <br />
              {{ patient.adresse }}
              <br />
              {{ patient.code_postal }} {{ patient.ville }}
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <strong>Email</strong>
              <br />
              {{ patient.email }}
              <v-icon @click="copier(patient.email)">mdi-content-copy</v-icon>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <strong>N° de téléphone</strong>
              <br />
              {{ patient.telephone }}
              <v-icon @click="copier(patient.telephone)"
                >mdi-content-copy</v-icon
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <strong>Motif de consultation</strong>
              <br />
              {{ patient.motif_de_consultation }}
            </v-col>

            <v-col cols="12" md="6" lg="4">
              <strong>Poids de départ</strong>
              <br />
              {{ patient.poids }} KG
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <strong>Poids souhaité</strong>
              <br />
              {{ patient.poids_souhaite }} KG
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <h2 class="mt-7">Consultations de suivi</h2>
      <div class="separateur my-3"></div>
      <v-data-table
        :headers="headers"
        :items="consultations_de_suivi"
        :items-per-page="15"
        class="elevation-2 mb-12"
        @click:row="voirConsultation"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.action="{ item }">
          <v-icon
            color="red"
            class="delete"
            @click.stop="confirmerSuppression(item.id)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </div>

    <!-- bouton fab -->
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          large
          v-on="on"
          bottom
          color="accent"
          dark
          fab
          fixed
          right
          @click="creerConsultationSuivi()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Créer une consultation de suivi</span>
    </v-tooltip>

    <!-- Confirmation de suppression  -->
    <v-dialog v-model="confirmerSupp" persistent max-width="490">
      <v-card>
        <v-card-title class="headline">Confirmer la suppression ?</v-card-title>
        <v-card-text>Cette action est définitive.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="confirmerSupp = false"
            >Annuler</v-btn
          >
          <v-btn color="red darken-1" text @click="supprimer">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- snackbar copier coller  -->
    <v-snackbar v-model="showSnackbar" bottom center :timeout="2000">
      {{ message }}
      <v-btn color="accent" text @click="showSnackbar = false">Fermer</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script lang="ts">
import { db, storage, functions } from "../main";
import dayjs from "dayjs";

export default {
  name: "FichePatient",
  data() {
    return {
      message: "",
      loadingCompte: false,
      photo_url: "images/placeholder.png",
      dialog: false,
      showSnackbar: false,
      confirmerSupp: false,
      visite_a_supprimer: "",
      photo: null,
      consultations_de_suivi: [],
      loading: true,
      loading_photo: false,
      headers: [
        {
          text: "Date",
          align: "left",
          value: "date",
        },
        { text: "Poids", value: "poids" },
        {
          text: "Actions",
          value: "action",
          align: "right",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getPatient();
    this.$emit("getTitre", "Fiche patient");
  },
  computed: {
    age() {
      return dayjs().diff(dayjs(this.patient.date_de_naissance), "years");
    },
    patient() {
      return this.$store.getters.patients.filter(
        (e) => e.id == this.$route.params.id
      )[0];
    },
  },
  methods: {
    async creerCompte() {
      this.loadingCompte = true;
      try {
        const fonctionCreer = functions.httpsCallable(
          "creerCompteManuellement"
        );
        await fonctionCreer({
          email: this.patient.email,
          password: this.patient.date_de_naissance,
          patientId: this.$route.params.id,
        });
        this.loadingCompte = false;
        this.toast("Compte créé !");
      } catch (error) {
        this.loadingCompte = false;
        this.toast("Une erreur s'est produite.");
      }
    },
    toast(message) {
      this.message = message;
      this.showSnackbar = true;
    },
    async copier(text) {
      await navigator.clipboard.writeText(text);
      this.toast("Copié !");
    },
    async getPatient() {
      let vm = this;
      this.loading = true;

      const getVisites = await db
        .collection("patients")
        .doc(this.$route.params.id)
        .collection("consultations")
        .orderBy("date", "desc")
        .get();

      // checker si le patient a une photo
      if (vm.patient.photo) {
        let storageRef = storage.ref();
        let photoRef = storageRef.child("photos_patients/" + vm.patient.photo);
        photoRef.getDownloadURL().then((res) => (vm.photo_url = res));
      }

      // 2e promise : récupérer les consultations
      getVisites.docs.forEach((doc) => {
        let consultation = doc.data();
        consultation.id = doc.id;
        consultation.date = dayjs(consultation.date.toDate()).format(
          "D MMMM YYYY"
        );
        vm.consultations_de_suivi.push(consultation);
      });

      vm.loading = false;
    },
    voirConsultation(consultation) {
      this.$router.push({
        name: "consultations-de-suivi.edit",
        params: {
          id_patient: this.$route.params.id,
          id: consultation.id,
        },
      });
    },
    async supprimer() {
      this.loading = true;
      await db
        .collection("patients")
        .doc(this.$route.params.id)
        .collection("consultations")
        .doc(this.visite_a_supprimer)
        .delete();
      this.toast("Supprimé !");
      this.confirmerSupp = false;
      this.consultations_de_suivi = this.consultations_de_suivi.filter(
        (doc) => doc.id != this.visite_a_supprimer
      );
      this.visite_a_supprimer = "";
      this.loading = false;
    },
    confirmerSuppression(id) {
      this.visite_a_supprimer = id;
      this.confirmerSupp = true;
    },
    creerConsultationSuivi() {
      this.$router.push({
        name: "consultations-de-suivi.create",
        params: {
          id_patient: this.$route.params.id,
        },
      });
    },
    async uploadPhoto() {
      let vm = this;
      this.loading_photo = true;
      let storageRef = storage.ref();
      if (vm.patient.photo) {
        let photoRef = storageRef.child("photos_patients/" + vm.patient.photo);
        await photoRef.delete();
      }
      let timestamp = Number(new Date());
      let fileName =
        this.patient.nom +
        "_" +
        this.patient.prenom +
        "_" +
        timestamp.toString();
      storageRef = storage.ref().child("photos_patients/" + fileName + ".jpg");
      await storageRef.put(this.photo);
      await db
        .collection("patients")
        .doc(this.$route.params.id)
        .update({ photo: fileName + "_200x200.jpg" });
      this.dialog = false;
      this.loading_photo = false;
      await storageRef.getDownloadURL().then((res) => (vm.photo_url = res));
    },
  },
};
</script>

<style>
table tr {
  cursor: pointer;
}
</style>
