var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-col',[_c('v-container',{attrs:{"fluid":""}},[_c('v-alert',{attrs:{"dismissible":"","text":"","dense":"","color":"teal","icon":"mdi-check","border":"left"},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v("Patient supprimé avec succès.")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-text-field',{attrs:{"label":"Filtre de recherche","placeholder":"Rechercher...","autocomplete":"false","outlined":"","rounded":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-switch',{staticClass:"ml-2",attrs:{"label":"Archivés"},model:{value:(_vm.archives),callback:function ($$v) {_vm.archives=$$v},expression:"archives"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mb-16",attrs:{"headers":_vm.headers,"items":_vm.patientsFiltres,"items-per-page":15,"search":_vm.search},on:{"click:row":_vm.goToPatient},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!item.isArchive)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.archiver(item.id)}}},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-archive-arrow-down")])],1):_vm._e(),(item.isArchive)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.desarchiver(item.id)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-archive-arrow-up")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editPatient(item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmerSuppression(item.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}])}),_c('v-btn',{attrs:{"large":"","bottom":"","color":"accent","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.$router.push({
            name: 'patients.create',
            params: {
              mod: 'create',
            },
          })}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"490"},model:{value:(_vm.confirmerSupp),callback:function ($$v) {_vm.confirmerSupp=$$v},expression:"confirmerSupp"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmer la suppression ?")]),_c('v-card-text',[_vm._v("Cette action est définitive.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){_vm.confirmerSupp = false}}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.supprimer}},[_vm._v("Confirmer")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }