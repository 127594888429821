<template>
   <div>
      <formulaire-bilan mode="create" @quitter="peut_quitter=true"></formulaire-bilan>
   </div>
</template>

<script>
   import FormulaireBilan from "@/components/FormulaireBilan";

   export default {
      data: () => ({
         peut_quitter: false
      }),
      components: { FormulaireBilan },
      mounted() {
         this.$emit("getTitre", "Nouveau patient");
      },
      beforeRouteLeave(to, from, next) {
         if (this.peut_quitter == false) {
            const answer = window.confirm("Quitter sans sauvegarder ?");
            if (answer) {
               next();
            } else {
               next(false);
            }
         } else {
            next();
         }
      }
   };
</script>

<style>
</style>
