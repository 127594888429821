<template>
  <div class="pa-4">
    <v-text-field
      v-model="search"
      rounded
      filled
      placeholder="Rechercher"
    ></v-text-field>
    <v-data-table
      :search="search"
      :items="documents"
      :headers="headers"
      :items-per-page="30"
      @click:row="editDocument"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.action="{ item }">
        <v-btn icon @click.stop="viewDocument(item)"
          ><v-icon color="primary">mdi-eye</v-icon></v-btn
        >
        <v-btn icon @click.stop="confirmerSuppression(item)"
          ><v-icon color="red">mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <!-- FAB  -->
    <v-btn
      large
      bottom
      color="green"
      dark
      fab
      fixed
      right
      @click="createDocument"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <!-- / FAB  -->

    <!-- Dialog confirmation suppression  -->
    <v-dialog v-model="confirmerSupp" persistent max-width="490">
      <v-card>
        <v-card-title class="headline">Confirmer la suppression ?</v-card-title>
        <v-card-text>Cette action est définitive.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="confirmerSupp = false"
            >Annuler</v-btn
          >
          <v-btn color="red darken-1" text @click="supprimer">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Dialog confirmation suppression  -->

    <CreateDocumentDialog
      @close="showEditDialog = false"
      :document="selectedDocument"
      v-if="showEditDialog"
    />
  </div>
</template>

<script>
import { db } from "../main";
import CreateDocumentDialog from "./Documents/CreateDocumentDialog.vue";

export default {
  components: {
    CreateDocumentDialog,
  },

  data: () => ({
    confirmerSupp: false,
    showEditDialog: false,
    selectedDocument: null,
    search: "",
    headers: [
      {
        text: "Nom",
        align: "left",
        value: "nom",
        sortable: true,
      },
      {
        text: "Catégorie",
        align: "left",
        value: "prettyCategorie",
        sortable: true,
      },
      {
        text: "Actions",
        value: "action",
        align: "right",
        sortable: false,
      },
    ],
  }),

  computed: {
    categories() {
      return this.$store.state.categories;
    },
    documents() {
      return this.$store.state.documents.map((doc) => ({
        ...doc,
        id: doc.id,
        prettyCategorie: this.prettifyCat(doc.categorieId),
      }));
    },
  },

  mounted() {
    this.$emit("getTitre", "Documents");
    this.getDocuments();
  },

  methods: {
    prettifyCat(id) {
      const cat = this.categories.filter((c) => c.id === id);
      if (cat.length) return cat[0].nom;
      return "Catégorie inconnue ou supprimée";
    },
    async getDocuments() {
      if (!this.$store.state.documentsLoaded) {
        this.loading = true;
        const getDocs = this.$store.dispatch("bindDocuments");
        const getCats = this.$store.dispatch("bindCategories");
        await Promise.all[(getDocs, getCats)];
        this.$store.commit("setDocumentsLoaded", true);
        this.loading = false;
      }
    },
    createDocument() {
      this.selectedDocument = null;
      this.showEditDialog = true;
    },
    editDocument(doc) {
      this.selectedDocument = doc;
      this.showEditDialog = true;
    },
    confirmerSuppression(doc) {
      this.selectedDocument = doc;
      this.confirmerSupp = true;
    },
    async supprimer() {
      this.confirmerSupp = false;
      await db.collection("documents").doc(this.selectedDocument.id).delete();
      this.selectedDocument = null;
    },
    viewDocument(doc) {
      window.open(doc.url, "_blank");
    },
  },
};
</script>
