<template>
   <div>
      <formulaire-suivi mode="edit"></formulaire-suivi>
   </div>
</template>

<script>
import FormulaireSuivi from "@/components/FormulaireSuivi";

export default {
   data: () => ({}),
   components: { FormulaireSuivi },
   mounted() {
      this.$emit("getTitre", "Consultation de suivi");
   }
};
</script>

<style>
</style>
